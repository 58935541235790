<script>
export default {
  emits: ['close-message'],
  props: {
    message: String, //上层带过来的数据
  },
};
</script>

<template>
  <div class="notification-bar">
    <div class="notification">
      <div class="scrolling-text"><var-icon name="/img/volume-high.png" size="18px" color="white"/><span class="messagefont">{{ message }}</span></div>
    </div>
  </div>
</template>

<style scoped>
.messagefont {
    font-weight: bolder;
    font-style: oblique;
    font-family: 'Apple LiGothic Medium', 'STKaiti', 'KaiTi_GB2312', 'Microsoft YaHei';
    font-size: 14px;
    color: rgb(9, 10, 83)
}

.notification-bar {
  display: flex;
  overflow: hidden;
  white-space: nowrap;
}

.notification {
  animation: scrollinout 10s linear infinite;
  /* 使用 @keyframes 定义的动画 */
}

.scrolling-text {
  animation: slideinout 10s linear infinite;
  /* 使用 @keyframes 定义的动画 */
}

@keyframes scrollinout {
  0% {
    transform: translateX(100%);
    /* 从右边滚入 */
  }

  100% {
    transform: translateX(-100%);
    /* 从右边滚入 */
  }
}

@keyframes slideinout {
  0% {
    transform: translateX(100%);
    /* 从右边滚入 */
  }

  100% {
    transform: translateX(-100%);
    /* 从右边滚入 */
  }
}
</style>

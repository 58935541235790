import { createRouter, createWebHashHistory } from 'vue-router'
/*
import weatherView from '../views/weatherView.vue'
import weatherListView from '../views/weatherListView.vue'
import liveHomeView from '../views/liveHomeView.vue'
import cityWeatherView from '../views/cityWeatherView.vue'
import userManageView from '../views/userManageView.vue'
import forumInfoView from '../views/forumInfoView.vue'
import foodMainView from '../views/foodMainView.vue'
import aboutInfoView from '../views/aboutInfoView.vue'
import loadInfoView from '../views/loadInfoView.vue'*/

//实现懒加载，用的时候才加载，为了打包之后页面快点
const loadInfoView = () => import('../views/loadInfoView.vue');
const liveHomeView = () => import('../views/liveHomeView.vue');
const weatherView = () => import('../views/weatherView.vue');
const weatherListView = () => import('../views/weatherListView.vue');
const cityWeatherView = () => import('../views/cityWeatherView.vue');
const userManageView = () => import('../views/userManageView.vue');
const forumInfoView = () => import('../views/forumInfoView.vue');
const foodMainView = () => import('../views/foodMainView.vue');
const aboutInfoView = () => import('../views/aboutInfoView.vue');

const routes = [
  {
    path: '/',
    name: 'load',
    meta: {
      title: '旅居生活，快乐人生',
    },
    component: loadInfoView
  }, {
    path: '/home',
    name: 'home',
    meta: {
      title: '旅居生活，快乐人生',
    },
    component: liveHomeView
  },
  {
    path: '/weather',
    name: 'weather',
    meta: {
      title: '适宜地区查询',
    },
    component: weatherView
  },
  {
    path: '/weatherlist',
    name: 'weatherlist',
    meta: {
      title: '享受生活，快乐人生',
    },
    component: weatherListView
  },
  {
    path: '/cityweather',
    name: 'cityweather',
    meta: {
      title: '城市天气查询',
      name: "viewport",
      //下一行是在输入框点击的时候，页面不自动放大，否则影响效果,在indxe.html中已经包含了
      //content: "width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no",
    },
    component: cityWeatherView
  },
  {
    path: '/userManage',
    name: 'userManage',
    meta: {
      title: '用户管理',
    },
    component: userManageView
  },
  {
    path: '/forum',
    name: 'forum',
    meta: {
      title: '谈天说地',
    },
    component: forumInfoView
  },
  {
    path: '/food',
    name: 'food',
    meta: {
      title: '各地美食',
    },
    component: foodMainView
  },
  {
    path: '/about',
    name: 'about',
    meta: {
      title: '关于本站',
    },
    component: aboutInfoView
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  window.document.title = to.meta.title
  next()
})

export default router

<template>
  <!--top-component ref="topBarRef" /不好用-->
  <router-view ></router-view>
  <!--底部导航栏-->
  <bottom-component />
</template>


<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

nav {
  padding: 30px;
}

/*以下去掉链接中的下划线显示*/
a {
  text-decoration: none;
}

.router-link-active {
  text-decoration: none;
}
</style>
//递归形成字典，实现地区，省，市级联关系的字典
function convertToNestedStructure(data) {
  const result = [];

  // 用于辅助构建子节点的递归函数
  function buildChildren(parentName) {
    const childrenMap = new Map(); // 使用 Map 来存储子节点，以避免重复
    data.forEach(item => {
      if (item.parent === parentName) {
        const childName = item.child;
        if (!childrenMap.has(childName)) {
          childrenMap.set(childName, {
            text: childName,
            children: buildGrandchildren(childName)
          });
        }
      }
    });
    return Array.from(childrenMap.values());
  }

  // 用于辅助构建孙节点的递归函数
  function buildGrandchildren(childName) {
    const grandchildren = data
      .filter(item => item.child === childName)
      .map(item => ({ text: item.grandchild }));
    return grandchildren.length ? grandchildren : null;
  }

  // 找到所有顶级的父节点
  const topLevelParents = [...new Set(data.map(item => item.parent))];

  topLevelParents.forEach(parent => {
    result.push({
      text: parent,
      children: buildChildren(parent)
    });
  });

  return result;
}

function CreatecityCodeMap(dataDict) { //生成省+市，找到citycode的结构
  const result = dataDict.reduce((acc, item) => {
    const key = item.prov_name + item.city_name;
    acc[key] = item.city_code;
    return acc;
  }, {});
  return result
}


function openZhihu(searchContent) { //由于知乎的安全设置，打开知乎,只能用新窗口方式
  let zhihulUrl = 'https://www.zhihu.com/search?type=content&q=' + searchContent;
  console.log(zhihulUrl)
  window.open(zhihulUrl, '_blank');
}
//检查手机格式
function checkTel(tel) {
  if (tel == '') {
    return true
  }
  const regex = /^1[3-9]\d{9}$/;  //用正则表达式校验手机号码
  if (!regex.test(tel)) {
    return false
  }
  else {
    return true
  }
}
//检查email格式
function checkEmail(email) {
  if (email == '') {
    return true
  }
  const regex = /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;  //用正则表达式校验邮箱
  if (!regex.test(email)) {
    return false
  }
  else {
    return true
  }
}
//检查密码输入的一致性
function checkPass(password, repassword) {
  if (password != '' && repassword != '' && password != repassword) {
    return false
  }
  else {
    return true
  }

}

//把pk与fields拉平到一个数据结构中
function preShowData(fields) {
  const newData = fields.map(item => {  
    const pk = item.pk;
    const fields = item.fields;
    const fieldsData = Object.entries(fields).map(([key, value]) => ({ [key]: value }));
    return {
      pk,
      ...Object.assign({}, ...fieldsData)
    };
  });
  return newData
}
module.exports = {
  convertToNestedStructure,
  openZhihu,
  checkTel,
  checkEmail,
  checkPass,
  CreatecityCodeMap,
  preShowData,
};
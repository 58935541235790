import { createApp } from 'vue'
import App from './App.vue'
import Varlet from '@varlet/ui'
import '@varlet/ui/es/style'
import router from './router'
import axios from 'axios'
import bottomNav from './components/bottomNav.vue'
//import topBar from './components/topBar.vue' 
import { decryptByDES } from '@/myutils/3des.js'; //自定义的加密解密js
import { markDataAsLoaded } from '@/myutils/eventBus'; // 导入 eventBus

const app = createApp(App);
app.config.globalProperties.$axios = axios

//通过VUE_APP_NODE_ENV判断环境，如果是生产环境就直接用ip连接，开发环境在vue.config.js中配置了proxy，通过那个访问
//目前发布到服务器还用dev开发环境的内容发，因为服务器上nginx配置了一个"proxy_pass http://127.0.0.1:9850/weather/;"用来解决打包时开发代理不能打包的问题
//而开发代理是为了解决同源访问后台的问题，否则后台会有跨越限制（配置了跨域但是好像不起作用）
console.log(`env:${process.env.VUE_APP_NODE_ENV}`)
const isProduction = process.env.VUE_APP_NODE_ENV === 'production';
axios.defaults.baseURL = isProduction ? process.env.VUE_APP_BACKEND_URL + '/weather' : '/api';

//区域省市级联数据好几个地方在用，所以放到这里统一提供给后面，但是由于加载慢，如果没有加载好，后面页面还要判断没数据的时候自己加载
const _key = process.mykeyiv.VUE_APP_SECRET_KEY;
const _iv = process.mykeyiv.VUE_APP_IV;
axios.get(`/getapc/`).then(response => {
    //服务器端django用serializers.serialize方法对queryset序列化，返回的值都放到fields里面
    //如果用其他方法序列化，返回的值就不是这样放了
    let decryptedData = decryptByDES(response.data, _key, _iv) //解密
    let data = JSON.parse(decryptedData)
    let fields = data.map(item => (item.fields))
    app.provide('apcDatafromMain', fields);
    // 数据加载完成后标记为已加载
    markDataAsLoaded();
})

//app.component('top-component', topBar)  
app.component('bottom-component', bottomNav)
app.use(router).use(Varlet).mount('#app')


<script>
import { ref } from 'vue'
import { useRouter } from "vue-router";
import axios from 'axios';
import tools from '@/myutils/tools.js'; //自己的公共js
import { decryptByDES } from '@/myutils/3des.js'; //自定义的加密解密js
import notiInfo from '@/components/notiInfo.vue'; //内嵌网站信息内容的组件

const _key = process.mykeyiv.VUE_APP_SECRET_KEY;
const _iv = process.mykeyiv.VUE_APP_IV;

export default {
    components: {// 注册子组件
        'noti-component': notiInfo,
    },
    data() {
        return {
            active: 0,
            router: useRouter(),
            showDiv: ref(false),
            bottomKey: 0,
            //处理后台消息
            messageData: [],
            messageCount: 0,
            timerId: '',
            timerIdmessage: '',   //多个消息定时轮询
            timerIdcontinued: '',   //消息在页面持续显示时间定时器
            //消息子组件
            showNoti: ref(false), // 控制子组件是否显示
            componentNotiKey: 0, // 用于强制重新渲染子组件的 key
            toNotimessage: '',
            //保存apc的json
            //apcDataString: '',
        };
    },
    created() {
        this.checkForNewMessages(); // 页面加载后立即检查消息
        this.timerId = setInterval(this.checkForNewMessages, 60000 * parseInt(process.message.VUE_APP_GETDATA)); // 每隔多少分钟检查一次消息
        //console.log(`定时器id：${this.timerId}`)
    },
    beforeMount() {
        // 在子组件挂载前执行脚本代码

    },
    mounted() {
        //console.log(`bottomapcData:${this.apcDatafromMain}`)
    },
    methods: {
        checkForNewMessages() {
            //axios.get(`/api/getmess/`).then(response => {
            axios.get(`/getmess/`).then(response => {
                //服务器端django用serializers.serialize方法对queryset序列化，返回的值都放到fields里面
                //如果用其他方法序列化，返回的值就不是这样放了
                let decryptedData = decryptByDES(response.data, _key, _iv) //解密
                //console.log(decryptedData)
                let data = JSON.parse(decryptedData)
                let fields = data.map(item => ({
                    pk: item.pk,
                    fields: item.fields,
                }))
                const newData = tools.preShowData(fields)
                this.messageData = newData.map(item => ({
                    messageid: item.pk,
                    message: item.message,
                }))
                //console.log(`信息个数：${this.messageData.length}`)
                if (this.messageData.length > 0) {
                    //console.log(`closemessage3:${this.timerIdmessage}`)
                    clearInterval(this.timerIdmessage);
                    this.messageCount = 0
                    this.showmessage()
                    this.timerIdmessage = setInterval(this.showmessage, 60000 * parseInt(process.message.VUE_APP_SHOWTIME));      //多久开始下一个消息显示
                    //console.log(`定时器3id：${this.timerIdmessage}`)
                }
            })
        },
        showmessage() {
            if (this.messageCount == this.messageData.length) { //轮巡
                this.messageCount = 0
            }
            this.toNotimessage = this.messageData[this.messageCount].message
            this.showNoti = true
            this.timerIdcontinued = setInterval(this.closemessage, 60000 * parseInt(process.message.VUE_APP_CONTINUED)); // 每个消息持续时间
            //console.log(`定时器2id：${this.timerIdcontinued}`)
            this.messageCount += 1
        },
        closemessage() {
            //console.log(`closemessage2:${this.timerIdcontinued}`)
            this.showNoti = false
            clearInterval(this.timerIdcontinued);
        },
        handleChange() {
            //console.log(this.active)
            switch (this.active) {
                case 0:
                    this.router.push("/home");
                    break;
                case 1:
                    this.router.push("/food");
                    break;
                case 2:
                    this.router.push("/weather");
                    break;
                case 3:
                    this.router.push("/forum");
                    break;
                case 4:
                    this.router.push("/userManage");
                    break;
                default:
                    this.router.push("/");
                    break;
            }
        },
        /* 已经改用inject传输数据
        apctopath(topath) {
            if (this.apcDataString !== undefined && this.apcDataString!='') {
                //console.log(`bottomapcdatayyyy:${this.apcDataString}`)
                let decryptedData = this.apcDataString
                let data = JSON.parse(decryptedData)
                let fields = data.map(item => (item.fields))
                this.router.push({
                    path: topath,
                    state: {
                        apcdata: JSON.stringify(fields)
                    }
                });
            }
            else {
                console.log(`bottomapcdata2:${this.apcDataString}`)
                //axios.get(`https://192.168.0.111:8000/weather/getapc/`).then(response => {
                //console.log(apiUrl+`weather/getapc/`)
                //this.showDiv = true //打开遮罩层
                axios.get(`/getapc/`).then(response => {
                    //服务器端django用serializers.serialize方法对queryset序列化，返回的值都放到fields里面
                    //如果用其他方法序列化，返回的值就不是这样放了
                    this.showDiv = false //关闭遮罩层
                    let decryptedData = decryptByDES(response.data, _key, _iv) //解密
                    let data = JSON.parse(decryptedData)
                    let fields = data.map(item => (item.fields))
                    //把数据存到apcDatafromMain
                    this.apcDataString = decryptedData
                    this.router.push({
                        path: topath,
                        state: {
                            apcdata: JSON.stringify(fields)
                        }
                    });
                })
            }
        },*/
    },
}
</script>
<template :key="bottomKey">
    <div class="message-popup" v-if="showNoti">
        <noti-component :key="componentNotiKey" :message="toNotimessage" @close-message="closemessage" />
    </div>
    <var-bottom-navigation v-model:active="active" border fixed safe-area @click="handleChange">
        <var-bottom-navigation-item label="首页" icon="/img/home.png" />
        <var-bottom-navigation-item label="美食" icon="/img/food-turkey.png" />
        <var-bottom-navigation-item label="找地" icon="/img/train-car.png" />
        <var-bottom-navigation-item label="杂谈" icon="/img/archive-edit.png" />
        <var-bottom-navigation-item label="我的" icon="/img/account.png" />
    </var-bottom-navigation>
    <a href="https://beian.miit.gov.cn/">津ICP备2023009815号</a>
</template>
<style scoped>
.message-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: auto;
    background-color: rgb(255, 255, 255);
    /* 半透明背景 */
    display: flex;
    flex-direction: column;
    align-items: right;
    justify-content: left;
    overflow-x: hidden;
    /* 隐藏横向滚动条 */
    z-index: 999;
}
</style>
import { ref } from 'vue';

// 创建一个 ref 来存储数据加载状态
const isDataLoaded = ref(false);

// 创建一个函数来标记数据已加载
function markDataAsLoaded() {
  isDataLoaded.value = true;
  // 数据加载完成后触发自定义事件
  const event = new Event('data-loaded');
  document.dispatchEvent(event);
}

export { markDataAsLoaded };